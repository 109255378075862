<template>
    <div>
        <ClientOnly>
            <swiper-container
                ref="swiper"
                :auto-height="true"
                :auto-width="true"
                :calculate-height="true"
                :slides-per-view="1.5"
                :space-between="10"
            >
                <swiper-slide v-if="data && data.length" class="swiper-slide swiper-height" v-for="item in data">
                    <ProfileCard :profile="item" :md="true" :show-city="true"></ProfileCard>
                </swiper-slide>
            </swiper-container>
        </ClientOnly>
    </div>
</template>

<script setup>
import { register } from 'swiper/element/bundle';
import ProfileCard from "@/Components/ProfileCard.vue";
register();

defineProps(['data'])

</script>
<style scoped>
.swiper-height {
    height: auto;
}
</style>
